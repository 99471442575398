import { Component, OnInit, inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { firstValueFrom } from "rxjs";
import * as _ from "lodash";
import { Constants } from "../../../constants/constants";
import { TaskSet } from "../automation";
import { AutomationService } from "../automation.service";
import { TitleService } from "../../../services/title.service";
import { FormBuilder } from "@angular/forms";
import { ZxFormsValidators } from "../../../components/forms-new/utilities/zx-forms-validators";
import { ZX_FORMS_TOOLTIPS_CONFIGS } from "../../../components/forms-new/utilities/zx-forms-tooltips-configs";
import { FORM_ACTION, ZxFormsHelper } from "../../../components/forms-new/utilities/zx-forms-helper";

@Component({
    selector: "app-automation-form",
    templateUrl: "./automation-form.component.html"
})
export class AutomationFormComponent implements OnInit {
    // Helpers
    ZX_FORMS_TOOLTIPS_CONFIGS = ZX_FORMS_TOOLTIPS_CONFIGS;
    ZxFormsHelper = ZxFormsHelper;
    // Services
    private formBuilder = inject(FormBuilder);
    private activatedRoute = inject(ActivatedRoute);
    private router = inject(Router);
    private automationService = inject(AutomationService);
    private titleService = inject(TitleService);
    // Variables
    taskSet: TaskSet = null;
    isSaving = false;
    isLoading = true;
    isSubmitted = false;
    formAction: FORM_ACTION = null;
    form = this.formBuilder.group({
        name: [
            null as string,
            [
                ZxFormsValidators.required(),
                ZxFormsValidators.minLength(2),
                ZxFormsValidators.regexPattern(Constants.validators.name, "ZX_VALIDATION_ERRORS.REGEX_PATTERN.NAME")
            ],
            [ZxFormsValidators.duplication(this.getTasksSetsNames.bind(this))]
        ],
        resource_tag_ids: [[] as number[], ZxFormsValidators.required("ZX_VALIDATION_ERRORS.REQUIRED.TAGS")]
    });

    async ngOnInit() {
        const actionParam = this.activatedRoute.snapshot.paramMap.get("action") as
            | FORM_ACTION.EDIT
            | FORM_ACTION.CLONE
            | null;
        this.formAction = actionParam === null ? FORM_ACTION.CREATE : actionParam;
        this.taskSet = await this.getCurrentTask();
        if (ZxFormsHelper.actions.isEdit(this.formAction) || ZxFormsHelper.actions.isClone(this.formAction)) {
            this.form.setValue({
                name: ZxFormsHelper.actions.isClone(this.formAction) ? null : this.taskSet.name,
                resource_tag_ids: _.map(this.taskSet.resourceTags, "id")
            });
        }
        this.titleService.setTitle("TASK_SET", this.formAction, this.taskSet);
        this.isLoading = false;
    }

    async onSubmit() {
        this.isSubmitted = true;
        if (this.form.invalid) {
            return;
        }
        this.isSaving = true;
        this.form.disable();
        const taskSet = ZxFormsHelper.actions.isEdit(this.formAction)
            ? await this.automationService.updateTaskSet(this.taskSet, this.form.value)
            : await this.automationService.addTaskSet(this.form.value);
        if (taskSet) {
            this.navigateToTaskSetDetails(taskSet.name);
        }
        this.form.enable();
        this.isSaving = false;
    }

    async getCurrentTask() {
        // TODO: query by id it much more cleaner and require less communication with the server
        await firstValueFrom(this.automationService.refreshTaskSets(true));
        return ZxFormsHelper.actions.isCreate(this.formAction)
            ? null
            : this.automationService.getCachedTaskSet(this.activatedRoute.snapshot.paramMap.get("name")); //Always have a value because of the refreshTaskSets
    }

    navigateToTaskSetDetails(taskSetName: string) {
        this.router.navigate([Constants.urls.automation, taskSetName]);
    }

    navigateToTaskSetsList() {
        this.router.navigate([Constants.urls.automation]);
    }

    async getTasksSetsNames() {
        const taskSets = await firstValueFrom(this.automationService.refreshTaskSets());
        return ZxFormsHelper.actions.isEdit(this.formAction)
            ? _.without(_.map(taskSets, "name"), this.taskSet.name)
            : _.map(taskSets, "name");
    }
}

<content-loading-animation *ngIf="isLoading"></content-loading-animation>
<form *ngIf="!isLoading" [formGroup]="form" (ngSubmit)="onSubmit()">
    <zx-forms-title [titleTranslateKeys]="['TASK_SET']" [formAction]="formAction"></zx-forms-title>
    <zx-forms-content-wrapper>
        <zx-forms-text-input
            formControlName="name"
            [translateKey]="{ label: 'NAME' }"
            [tooltipsConfigs]="[ZX_FORMS_TOOLTIPS_CONFIGS.REQUIRED]"
            [isParentFormSubmitted]="isSubmitted"
        ></zx-forms-text-input>
        <zx-forms-tags-select
            formControlName="resource_tag_ids"
            [tooltipsConfigs]="[ZX_FORMS_TOOLTIPS_CONFIGS.REQUIRED, ZX_FORMS_TOOLTIPS_CONFIGS.TAGS_INFO]"
            tagsType="resource"
            [isParentFormSubmitted]="isSubmitted"
        ></zx-forms-tags-select>
        <app-error [marginBottom]="false" [marginTop]="true"></app-error>
        <hr />
        <zx-forms-control-bar
            [form]="form"
            [formAction]="formAction"
            [isSaving]="isSaving"
            [isSubmitted]="isSubmitted"
            [navigateToListPage]="navigateToTaskSetsList.bind(this)"
            [navigateToDetailsPage]="navigateToTaskSetDetails.bind(this, taskSet?.name)"
        ></zx-forms-control-bar>
    </zx-forms-content-wrapper>
</form>
